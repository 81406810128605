/* Basic styling */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: sans-serif;
  background: #f1f1f1;
}

nav {
  background: #222;
  padding: 5px 20px;
}

ul {
  list-style-type: none;
}

a {
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.logo a:hover {
  text-decoration: none;
}

.menu li {
  font-size: 16px;
  padding: 1.5rem 1rem;
  white-space: nowrap;
}

.logo {
  padding-right: 2rem;
}

.logo a, .toggle a {
  font-size: 20px;
}

.button.secondary {
  border-bottom: 1px #444 solid;
}

/* Mobile menu */
.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.toggle {
  order: 1;
}

.item {
  &.button {
    order: 2;
  }
  width: 100%;
  text-align: center;
  order: 3;
  display: none;
  &.active {
    display: block;
  }
}

/* Tablet menu */
@media all and (min-width: 600px) {
  .menu {
    justify-content: center;
  }
  // REMOVED FOR GUTTERS
  // .logo {
  //   flex: 1;
  // }
  .toggle {
    flex: 1;
    text-align: right;
  }
  .item.button {
    width: auto;
    order: 1;
    display: block;
  }
  .toggle {
    order: 2;
  }
  .button {
    &.secondary {
      border: 0;
    }
    a {
      padding: 7.5px 15px;
      background: teal;
      border: 1px #006d6d solid;
    }
    &.secondary a {
      background: transparent;
    }
    a:hover {
      text-decoration: none;
    }
    &:not(.secondary) a:hover {
      background: #006d6d;
      border-color: #005959;
    }
    &.secondary a:hover {
      color: #ddd;
    }
  }
}

/* Desktop menu */
@media all and (min-width: 900px) {
  .item {
    display: block;
    width: auto;
  }
  .toggle {
    display: none;
  }
  .logo {
    order: 0;
  }
  .item {
    order: 1;
  }
  .button {
    order: 2;
  }
  .menu li {
    padding: 15px 10px;
    &.button {
      padding-right: 0;
    }
  }
}