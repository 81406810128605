.why-me {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 1rem;

  h1 {
    color: white;
    width: 40%;
    border: 0.2rem solid white;
    padding: 0.8rem;
    text-align: center;
    align-self: center;
  }

  p {
    color: white;
    font-style: italic;
    font-weight: bold;
    font-size: 1rem;
    margin: 2rem;
    text-align: center;
  }
}

#icon-row {
  display: flex;
  justify-content: space-evenly;
  color: blue;

  &,
  &:before,
  &:after {
    transition: all 0.3s;
  }
  
  div {
    padding: 1rem;
    font-size: 3rem;
    border: 1px solid white;
    border-radius: 1rem;
    cursor: pointer;

    &:before,
    &:after {
      transform: rotate(0)
    }

    &:hover:after {
      transform: rotate(180deg);
    }

    &:hover:after {
      margin-top: -3px;
      transform: rotate(360deg);
      width: 30px;
      margin-left: -16px;
    }

    &:hover:before {
      margin-top: -3px;
      transform: rotate(450deg);
      box-shadow: none;
      width: 30px;
      margin-left: -16px;
    }
  }
}

@media all and (max-width: 480px) {
  .why-me {
    h1 {
      font-size: 0.8rem;
    }
  }
  #icon-row {
    flex-direction: column;
  }
}

@media all and (max-width: 839px) {
  .why-me {
    h1 {
      font-size: 1rem;
    }
  }
  #icon-row {
    // justify-content: column;
  }
} 

@media all and (max-width: 1024px) {  } 